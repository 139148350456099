.footer {
    background-color: #000;
    color: #fff;
    padding: 10px 0px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto; /* Center content horizontally */
    padding: 0 20px; /* Add padding to create space around content */
}

.footer-left {
    display: flex;
    align-items: center;
}

.footer-logo {
    width: 150px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 20px; /* Space between logo and text */
}

.footer-right {
    display: flex;
    align-items: center;
}

.social-icon {
    width: 30px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-left: 10px; /* Space between icons */
}

.footer-content p {
    margin: 0;
}

.footer-content nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-content nav ul li {
    display: inline-block;
    margin-right: 20px;
}

.footer-content nav ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.footer-content nav ul li a:hover {
    color: #ccc;
}

@media (max-width: 1100px){

    .footer {
        background-color: #000;
        color: #fff;
        padding: 0px 0px;
        text-align: center;
        position: relative;
        bottom: 0;
        width: 100%;
    }

    .footer-logo {
        width: 80px; /* Adjust size as needed */
        height: auto; /* Maintain aspect ratio */
        margin-right: 20px; /* Space between logo and text */
    }

}